import React from 'react'
import ExternLink from './externLink'
import FB from '../images/Social/fb.svg'
import GH from '../images/Social/gh.svg'
import Instagram from '../images/Social/insta.svg'
import LinkedIn from '../images/Social/linkedin.svg'
import Reddit from '../images/Social/reddit.svg'
import SO from '../images/Social/so.svg'
import Twitter from '../images/Social/twitter.svg'

const profiles = [
	{
		link: `https://github.com/anshumanv`,
		icon: GH,
		alt: `Anshuman's most of the code and OSS work.`
	},
	{
		link: `https://twitter.com/anshumaniac`,
		icon: Twitter,
		alt: `Anshuman's thoughts, opinions, views.`
	},
	{
		link: `https://www.linkedin.com/in/anshumanv12`,
		icon: LinkedIn,
		alt: `Anshuman's professional space.`
	},
	// {
	// 	link: `https://www.facebook.com/anshumanv12`,
	// 	icon: FB,
	// 	alt: `Anshuman's Facebook.`
	// },
	// {
	// 	link: `https://stackoverflow.com/users/7007100/anshuman-verma`,
	// 	icon: SO,
	// 	alt: `Where Anshuman looks up but rarely answers questions.`
	// },
	// {
	// 	link: `https://www.reddit.com/user/anshumanv`,
	// 	icon: Reddit,
	// 	alt: `Anshuman's Reddit.`
	// },
	// {
	// 	link: `https://www.instagram.com/anshumaniac/`,
	// 	icon: Instagram,
	// 	alt: `Anshuman's Instagram where posts are made when he's bored.`
	// }
]
const Social = props => {
	return (
		<div className="flex justify-center my-10">
			{profiles.map(profile => (
				<ExternLink
					className="mr-5"
					href={profile.link}
					key={profile.alt}
					title={profile.alt}
				>
					<img alt={profile.alt} src={profile.icon} />
				</ExternLink>
			))}
		</div>
	)
}

export default Social
