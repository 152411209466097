import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import ExternLink from '../components/externLink'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Social from '../components/social'

function IndexPage({
	data: {
		file,
		site: {
			siteMetadata: { description, author, sourcerer }
		}
	}
}) {
	return (
		<Layout>
			<SEO
				description={description}
				keywords={[
					`anshumanv`,
					`personal site`,
					`Anshuman Verma`,
					`Full Stack Engineer`,
					`Anime`,
					`Football`
				]}
				title={author}
			/>
			<section className=" flex flex-col flex-1">
				<div className="sm:flex flex-1 items-center">
					<Img
						alt="Anshuman Verma"
						className="block mx-auto max-h-sm w-1/2 rounded-full"
						fluid={file.childImageSharp.fluid}
					/>

					{/* <h2 className="bg-yellow-400 font-sans text-2xl font-serif inline-block my-8 p-3">
						ANSHUMAN VERMA
					</h2> */}
					<div className="leading-loose font-body sm:ml-6 sm:my-0 flex my-10 self-center">
						<div>
							Hi 👋, I'm{` `}
							<h1 className="text-lg inline-block text-teal-300">Anshuman</h1>.
							{` `}
							<h2 className="inline">A Full Stack Engineer</h2>
							{` `}
							{/* Engineering{' '}
							<ExternLink
								className="link"
								href="https://clarisights.com"
							>
								@clarisights
							</ExternLink>{' '} */}
							and passionate about web, OSS and tech in general. I also watch
							anime and play football.
						</div>
					</div>
				</div>
				<Social />
				<div className="flex my-10 justify-center">
					<ExternLink href="/resume.pdf" title={`Anshuman/'s Résumé.`}>
						<button className="bg-gray-700 hover:bg-gray-800 text-white font-bold py-2 mr-3 sm:mr-10  px-4 rounded-full">
							Résumé
						</button>
					</ExternLink>
					<ExternLink
						href={sourcerer}
						title="Check out all of my work summary."
					>
						<button className="bg-gray-700 hover:bg-gray-800 text-white font-bold py-2 px-4 rounded-full">
							Sourcerer
						</button>
					</ExternLink>
				</div>
			</section>
		</Layout>
	)
}

export const query = graphql`
	query {
		file(relativePath: { eq: "me.png" }) {
			childImageSharp {
				fluid {
					...GatsbyImageSharpFluid
				}
			}
		}
		site {
			siteMetadata {
				description
				author
				sourcerer
			}
		}
	}
`

export default IndexPage
